exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-sent-js": () => import("./../../../src/pages/contact/sent.js" /* webpackChunkName: "component---src-pages-contact-sent-js" */),
  "component---src-pages-cv-backend-js": () => import("./../../../src/pages/cv/backend.js" /* webpackChunkName: "component---src-pages-cv-backend-js" */),
  "component---src-pages-cv-frontend-js": () => import("./../../../src/pages/cv/frontend.js" /* webpackChunkName: "component---src-pages-cv-frontend-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

